import React, { useEffect, useState } from "react";
import { Dropdown, initTE } from "tw-elements";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ErrorBoundary } from "react-error-boundary";

import { Timeout } from "./components/shared/Timeout";
import HttpService from "./services/HttpService";
import UserService from "./services/UserService";
import LoaderBar from "./components/shared/LoaderBar";

import NavBar from "./components/shared/NavBar";
import OffCanvasMenu from "./components/shared/OffCanvas";
// import UserActiveCheck from "./components/introducer/UserActiveCheck"

import "./scss/App.scss";
import "./scss/ErrorBoundary.scss";

const date = () => {
  const date = new Date();
  const year = date.getFullYear();
  return year;
};

function logout() {
  sessionStorage.clear();
  UserService.doLogout();
}

const fetchIntroducerAttributes = async () => {
  const data = await HttpService.put(
    `User/LoggedInUserInfo?realmName=${UserService._kc.realm}`
  );
  return data;
};

function FallbackComponent({ error, resetErrorBoundary }) {
  return (
    <div className="error-boundary-message">
      <h1>An error has occured</h1>
      <p>There was an error loading this page.</p>
      <p>
        If the problem persists please contact RedZed Client Services on 1300
        722 462
      </p>
      <div className="error-boundary-footer flex flex-nowrap gap-x-1">
        <a href="/" className="red-btn btn w-1/2 p-2">
          Return to Home Page
        </a>
        <button className="btn w-1/2 p-2" onClick={logout}>
          Please Login Again
        </button>
      </div>
    </div>
  );
}
// Declare TalkdeskChatSDK globally
// let TalkdeskChatSDK;
export default function App() {
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem("attributeData")) !== undefined
      ? JSON.parse(sessionStorage.getItem("attributeData"))
      : null
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    initTE({ Dropdown });

    fetchIntroducerAttributes().then((data) => {
      setUserInfo(data);
      sessionStorage.setItem("attributeData", JSON.stringify(data));
      setIsLoading(false);
    });
  }, []);

  // let TalkdeskChatSDK;
  // useEffect(() => {
  //   // Only initialize Talkdesk if introducerid is available
  //   const isWorkingHours = () => {
  //     const now = new Date();
  //     const currentHour = now.getHours();
  //     const currentDay = now.getDay(); // 0 is Sunday, 6 is Saturday

  //     // Working days are Monday to Friday (1 to 5)
  //     if (currentDay < 1 || currentDay > 5) {
  //       return false;
  //     }

  //     // Working hours are from 9 AM (9) to 5 PM (17)
  //     return currentHour >= 9 && currentHour < 17;
  //   };

  //   if (!UserService?.isIntroducer() || !isWorkingHours()) {
  //     return;
  //   }

  //   const node = "tdWebchat";
  //   const props = {
  //     touchpointId: "4f14a3a2702841cb886c7bcdda7bc429",
  //     accountId: "",
  //     region: "td-us-1",
  //   };
  //   const configs = {
  //     enableValidation: false,
  //     enableEmoji: true,
  //     enableUserInput: true,
  //     enableAttachments: true,
  //     styles: {
  //       chatThemeColor: "#ff0000",
  //       chatTitle: "RedZed Customer Service",
  //       chatSubtitle: " ",
  //       chatPlaceholder: "Type a message...",
  //       avatarForegroundColor: "#5C6784",
  //       avatarBackgroundColor: "#EDF4FC",
  //       userBackgroundColor: "#ff0000",
  //       startChatButtonTextColor: "#FFFFFF",
  //       startChatButtonBackgroundColor: "#ff0000",
  //       chatConfirmButtonBackgroundColor: "#ff0000",
  //     },
  //   };

  //   if (window.TalkdeskChatSDK) {
  //     console.error("TalkdeskChatSDK already included");
  //     return;
  //   }

  //   const divContainer = document.createElement("div");
  //   divContainer.id = node;
  //   document.body.appendChild(divContainer);

  //   const src = "https://talkdeskchatsdk.talkdeskapp.com/talkdeskchatsdk.js";
  //   const script = document.createElement("script");
  //   script.type = "text/javascript";
  //   script.charset = "UTF-8";
  //   script.id = "tdwebchatscript";
  //   script.src = src;
  //   script.async = true;
  //   document.body.appendChild(script);

  //   script.onload = () => {
  //     // Assign TalkdeskChatSDK when the script is loaded
  //     TalkdeskChatSDK = window.TalkdeskChatSDK;
  //     window.webchat = TalkdeskChatSDK(node, props);

  //     // Set up event handlers and initialize after webchat is defined
  //     function setContext() {
  //       window.webchat.setContextParam({
  //         introducerid: userInfo?.preferred_username,
  //       });
  //     }

  //     // Send data when the chat conversation is initiated
  //     window.webchat.onConversationStart = function () {
  //       setContext();
  //     };

  //     // Send data when the chat widget is open
  //     window.webchat.onOpenWebchat = function () {
  //       setContext();
  //     };

  //     window.webchat.init(configs);
  //   };

  //   // Clean up function to remove the script when the component unmounts
  //   return () => {
  //     document.body.removeChild(script);
  //     document.body.removeChild(divContainer);
  //   };
  // }, [userInfo?.introducerid]);

  return (
    <div className="App">
      <Timeout>
        <ToastContainer theme="colored" />
        <header className="App-header mb-5 md:mb-0">
          <NavBar userInfo={userInfo} />
          <OffCanvasMenu />
        </header>
        <main className="main-container">
          {isLoading ? (
            <LoaderBar />
          ) : (
            <>
              {/* <UserActiveCheck> */}
              <ErrorBoundary FallbackComponent={FallbackComponent}>
                <Outlet />
              </ErrorBoundary>
              {/* </UserActiveCheck> */}
            </>
          )}
        </main>
        <footer className="App-footer flex items-center mt-5 md:mt-0 ">
          <p>
            Copyright {date()} RedZed Lending Solutions Pty Ltd. Australian
            Credit Licence 311128. ABN 31 123 588 527. All rights reserved
          </p>
        </footer>
      </Timeout>
    </div>
  );
}
